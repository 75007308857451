import React from "react";
import ReactTable from 'react-table-v6'
import { Component, Fragment } from "react";
import Moment from 'moment';
import { CometChat } from "@cometchat-pro/chat"
import { CometChatConversationListScreen, CometChatUserListScreen } from "../../../CometChat/";
import { getEmailHTML, getEmailText } from "./get-email-html";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	Fade
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
var moment = require('moment-timezone');

var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

class Chat extends React.Component {

	connectionListenerId = "connection_" + hash + new Date().getTime();
	userListenerId = "chatviewuser_" + hash + new Date().getTime();

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
      modal2: false,
      dropdownOpen: false,
      isLoggedIn: false,
      chatUser:{},
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
      user: {},
			isChatAvailable: false,
			timeNotice: "7am-4:30pm MST Monday through Wednesday | Thursday we are here 7am-3:30pm | Fridays 7am - noon"
	  };
  }

	async componentDidMount() {

		if (this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0) {
			console.log('Chat is available. Logging On...');
			//this.loginChat();
			setTimeout(this.loginChat, 1000);
		} else {
			this.isChatAvailable();
			setTimeout(this.loginChat, 1000);
		}

		//this.sendTestEmail();
		//this.attachConnectionListener();
		//this.attachListeners();
	}

	attachConnectionListener() {

		console.log("attachConnectionListener this.userListenerId ", this.userListenerId);

		CometChat.addConnectionListener(
		    this.connectionListenerId,
		    new CometChat.ConnectionListener({
		        onConnected: () => {
		            console.log("ConnectionListener => On Connected");
		        },
		        inConnecting: () => {
		            console.log("ConnectionListener => In connecting");
		        },
		        onDisconnected: () => {
		          console.log("ConnectionListener => On Disconnected");
		        }
		    })
		);
	}

	attachListeners() {

		console.log("attachListeners this.userListenerId ", this.userListenerId);

		CometChat.addUserListener(
				this.userListenerId,
				new CometChat.UserListener({
						onUserOnline: onlineUser => {
								/* when someuser/friend comes online, user will be received here */

								console.log("attachListeners onlineUser: ", onlineUser);
								//callback(onlineUser);
						},
						onUserOffline: offlineUser => {
								/* when someuser/friend went offline, user will be received here */

								console.log("attachListeners offlineUser: ", offlineUser);
								//callback(offlineUser);
						}
				})
		);
	}

	sendTestEmail = async () => {
		//var htmlBody = getEmailHTML(repairInfo);
		var textBody = getEmailText();
		var htmlBody = getEmailHTML();

		console.log('email html: ' + htmlBody);
		console.log('email text: ' + textBody);

		let requestBody = {
			emailRecipient:['ryan.a.stickel@gmail.com','rstickel@azenamedical.com'],
			emailSubject:"New Unread Messages",
			emailHTMLMessage:htmlBody,
			emailTextMessage:textBody
		}

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-notification-for-message";

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody)
		});

		const body = await response.json();
		console.log("sendTestEmail response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ repairCategories: body.response });
			console.log("Email Sent Successfully!");
			//this.goToRepairLog(repairID);
		} else {
			//ADD ERROR MESSAGE
			//this.goToRepairLog(repairID);
		}
	}

	isChatAvailable () {

		var format = 'HH:mm'
		var format2 = 'hh:mm a'

		var time = Moment();
		var dayOfWeek = Moment().day();
		//var currentLocalTime = Moment().format(format);
		//var mstTime = time.tz("MST");
		var pstTimeString = time.tz("PST").format(format2);
		//console.log('dayOfWeek: ', dayOfWeek);
		//console.log('currentLocalTime: ', currentLocalTime);
		console.log('pstTimeString: ', pstTimeString);
		var startTime = Moment('06:00', format);
		var endTime = Moment('11:01', format);

		/*if (dayOfWeek==1 || dayOfWeek==2 || dayOfWeek==3) {
			startTime = Moment("06:00", format);
			endTime = Moment("15:31", format);
			//endTime = Moment("09:13", format);
		} else if (dayOfWeek==4) {
			startTime = Moment('06:00', format);
			endTime = Moment('14:31', format);
		} else if (dayOfWeek==5) {
			startTime = Moment('06:00', format);
			endTime = Moment('11:01', format);
		}*/

		if (dayOfWeek>0 && dayOfWeek<6) {
			startTime = Moment("08:00", format);
			endTime = Moment("16:31", format);
		}

		console.log('dayOfWeek: ', dayOfWeek);
		console.log('startTime: ', startTime.tz("PST").format(format2));
		console.log('endTime: ', endTime.tz("PST").format(format2));

		if (dayOfWeek!=0 && dayOfWeek!=6) {


			if (time.tz("PST").isBetween(startTime.tz("PST"), endTime.tz("PST"))) {

			  console.log('Chat is on');
				this.setState({ isChatAvailable: true });

				console.log('Chat is available. Logging On...');
				//this.loginChat();
				setTimeout(this.loginChat, 1500);

			} else {

			  console.log('Chat is off');

				//TEST SET TRUE
				//this.setState({ isChatAvailable: true });

				//TEST UNCOMMENT
				//this.loginChat();
				//setTimeout(this.loginChat, 1500);
			}

		} else {
			console.log('Chat is off');
		}

	}

	loginChat = async () => {
    let authKey = "41791462bc6f3b9eea8086c20d15ccffb606bb70";
		// TEMP KEY FOR TESTING
    var uid = this.state.currentUser.payload.sub;

    var name = this.state.currentUser.payload.email;
		var email = this.state.currentUser.payload.email;
		var role = "default";

		if (this.state.currentUser.payload.given_name.length > 0 || this.state.currentUser.payload.family_name.length > 0) {
			name = this.state.currentUser.payload.given_name+" "+this.state.currentUser.payload.family_name;
		}

    if (this.state.currentUser.payload['custom:role']) {
      //uid = "tech01";
			if (this.state.currentUser.payload['custom:role']>0) {
				var role = "support";

				if (this.state.currentUser.payload.given_name.length > 0) {

					name = "Tech Support: "+this.state.currentUser.payload.given_name;
				}
			}

    }

    var user = new CometChat.User(uid);
    var userExists = false;

		let meta = {
			email
		};

    user.setName(name);
		user.setRole(role);
		user.setMetadata(meta);

		console.log("Chatview Create User...UID: "+uid);

    CometChat.createUser(user, authKey).then(
        userData1 => {
          console.log("Chatview chat user created", userData1);

					CometChat.getLoggedinUser().then(
						user => {
					  	if(!user){
								CometChat.login(uid, authKey).then(
			            userData2 => {
			              console.log("Chatview Login Successful:", { userData2 });
										localStorage.setItem('cometchatUser', JSON.stringify(userData2));
										this.setState({userData2});
			              this.setState({isLoggedIn: true});
										userData2.setMetadata(meta);
										CometChat.updateUser(userData2, authKey).then(
										    userData3 => {
										        console.log("user updated", userData3);
														localStorage.setItem('cometchatUser', JSON.stringify(userData3));
														this.setState({userData3});
										    }, error => {
										        console.log("error", error);
										    }
										);

			            },
			            error => {
			              console.log("Login failed with exception:", { error });
			            }
			          );
					    } else {
								this.setState({isLoggedIn: true});
							}
						}, error => {
							console.log("Something went wrong", error);
						}
					);
        },error => {
					console.log("Chatview chat user error:", {error});

					CometChat.getLoggedinUser().then(
						user => {
					  	if(!user){
								CometChat.login(uid, authKey).then(
			            userData2 => {
			              console.log("Chatview Login Successful:", { userData2 });
										localStorage.setItem('cometchatUser', JSON.stringify(userData2));
										this.setState({userData2});
										this.setState({isLoggedIn: true});
										userData2.setMetadata(meta);
										CometChat.updateUser(userData2, authKey).then(
										    userData3 => {
										        console.log("user updated", userData3);
														localStorage.setItem('cometchatUser', JSON.stringify(userData3));
														this.setState({userData3});
										    }, error => {
										        console.log("error", error);
										    }
										);
			            },
			            error => {
			              console.log("Chatview Login failed with exception:", { error });
										//this.loginChat();
			            }
			          );
					    } else {
								this.setState({isLoggedIn: true});
							}
						}, error => {
							console.log("Something went wrong", error);
						}
					);
        }
    )

    //var chatUser = CometChat.getUser(uid);
    //this.setState({chatUser})

  }

  // getData = async () => {
	//
	// 	const response = await fetch('/api/devices', {
	// 		method: 'GET',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 	});
	// 	const body = await response.json();
	// 	console.log("all devices: " + JSON.stringify(body.results));
	//
	// 	if (body.status == 200) {
	// 		this.setState({ devices: body.results });
	// 	} else {
  //     //ADD ERROR MESSAGE
  //   }
	// };

	render() {
		return (
      <Fragment>
        {this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 ? (
          <Fragment>
						<Row className="mb-3">
							<Col md="12">
								<h1 style={{color:'#737782'}}>Chat Support</h1>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md="12">
								{this.state.isLoggedIn &&
									<Fade in="true">
										<CometChatConversationListScreen/>
									</Fade>
								}
							</Col>
						</Row>
          </Fragment>
        ) : (
          <Fragment>
						<Row className="mb-3">
							<Col md="12">
								{this.state.isChatAvailable ? (
									<Fragment>
										<h1 style={{color:'#737782'}}>Tech Support Chat - available during business hours</h1>
									</Fragment>
								) : (
									<Fragment>
										<h1 style={{color:'#737782'}}>Tech Support Chat - not available at this moment.</h1>
									</Fragment>
								)}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md="12">
								{this.state.isChatAvailable ? (
									<Fragment>
										<span style={{color:'#fff'}}>Monday - Friday: 8am - 4:30pm PST</span><br></br>
									</Fragment>
								) : (
									<Fragment>
										<h3 style={{color:'#fff', marginTop: "15px"}}>Business Hours:</h3><br></br>
										<h3 style={{color:'#fff'}}>Monday - Friday: 8am - 4:30pm PST</h3><br></br>
									</Fragment>
								)}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md="12">
								{this.state.isChatAvailable && this.state.isLoggedIn &&
									<Fade in="true">
										<CometChatUserListScreen/>
									</Fade>
								}
							</Col>
						</Row>
          </Fragment>
        )}
      </Fragment>
		);
	}
}

export default Chat;
