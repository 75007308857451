import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import Moment from 'moment';
var moment = require('moment-timezone');

let lineOptions2 = {
	maintainAspectRatio: false,
	plugins: {
		datalabels: {
			display: true,
			color: "#e9c736",
			align: "end",
			anchor: "end",
			font: { size: "12", weight: "bold" }
		}
	},
	legend: {
		display: false,
		labels: { fontFamily: "Nunito Sans" }
	},
	scales: {
		yAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}],
		xAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}]
	},
	layout: {
		padding: 20,
	}
}

let lineOptions = {
	maintainAspectRatio: false,
	tooltips: {
		titleFontSize: 16,
		bodyFontSize: 14
	},
	scales: {
		xAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
			}
		}],
		yAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
			}
		}]
	},
	legend: {
		display: true,
		labels: {
		}
	}
};

var isAdmin = false;

class TotalProcedures extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			proceduresByDateData: {},
			noDevices: false,
			noDataForDevices: false,
			currentDataSetting: 1,
			startDate: Moment().format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Today",
			tooltipOpen: false,
			totalProceduresLabel: 0,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			marketType: localStorage.getItem('marketType') ? JSON.parse(localStorage.getItem('marketType')) : 0
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {

		document.body.addEventListener('set-market-type', this.handleMarketTypeChange.bind(this));

		if (this.state.currentUser) {
			if (this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]>0) {
				this.selectDropDownItem(2, "Week");
				isAdmin = true;
			}
		}

		if (this.state.currentUser) {
			this.getData();
		} else {
			setTimeout(()=>{ this.getData(); }, 1000);
		}

		if (this.state.marketType==0) {
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		} else {
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		}

		console.log("Start Date: " + JSON.stringify(this.state.startDate));
		console.log("End Date: " + JSON.stringify(this.state.endDate));
	}

	handleMarketTypeChange(event) {
    console.log("Total Procedures Market has changed to: ", event.detail);
    // your business logic here .. this.setState({...});
		this.setState({ marketType: event.detail.id });
		setTimeout(this.getData, 200);

		if (event.detail.id==0) {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		} else {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		}
  }

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 4
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 4
	    });
		}

		setTimeout(()=>{ this.getData(); }, 1000);
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		} else if (id==4) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		setTimeout(()=>{ this.getData(); }, 1000);
  }

	componentWillUnmount() {
		//console.log("TotalProcedures componentWillUnmount");
		clearInterval(this.intervalID);
		document.body.removeEventListener('set-market-type', this.handleMarketTypeChange.bind(this));
	}

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
		this.getData(rSelected);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'day').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'day').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==3) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		setTimeout(()=>{ this.getData(); }, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'day').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'day').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		else if (this.state.currentDataSetting==3) {
		 let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
		 let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
		 console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
		 console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
		 this.setState({ startDate: revisedStartDate });
		 this.setState({ endDate: revisedEndDate });
	 }
		setTimeout(()=>{ this.getData(); }, 1000);
	}

	getData = async () => {

		console.log("Start Date: " + JSON.stringify(this.state.startDate));
		console.log("End Date: " + JSON.stringify(this.state.endDate));

		var timezone = Moment.tz.guess();

		if (this.state.currentDataSetting == 2 || this.state.currentDataSetting == 3) {

			var token = "";

			if (this.state.currentUser) {
				token = this.state.currentUser.jwtToken;
			}

			if (this.props.authToken) {
				console.log("total-procedures this.props.authToken: " + this.props.authToken);
				token = this.props.authToken;
			}

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"total-procedures-by-day-date-range?MarketType="+this.state.marketType+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;
			console.log("totalprocedures date range url: " + url);

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: token
					},
				});

				const body = await response.json();
				console.log("totalprocedures by day date range body response: " + JSON.stringify(body));

				var total = 0;

				body.results.map((prop, key) => {
					total = total + parseInt(prop.total);
				});

				this.setState({
					totalProceduresLabel: total
				});

				var chartData = {
					labels: body.results.map(k => Moment.utc(k.date).format('DD')),
					datasets: [{
						label: 'Total Procedures',
						backgroundColor: '#e9c736',
						data: body.results.map(k => k.total)
					}]
				}

				if (this.state.currentDataSetting == 2) {
					chartData = {
						labels: body.results.map(k => Moment.utc(k.date).format('MM/DD')),
						datasets: [{
							label: 'Total Procedures',
							backgroundColor: '#e9c736',
							data: body.results.map(k => k.total)
						}]
					}
				}

				console.log("totalprocedures by day: "+JSON.stringify(chartData));

				if (body.status == 'Success') {
					if (body.results.length>0) {
						this.setState({ proceduresByDateData: chartData }, () => console.log("totalprocedures by day: "+this.state.proceduresByDateData));
						this.setState({
							noDataForDevices: false
						});
					} else {
						//this.state.noDataForDevices = true;
						if (this.state.proceduresByDateData.length>0) {
							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					}
					this.forceUpdate();
				} else {
					//this.state.noDataForDevices = true;
					if (this.state.proceduresByDateData.length>0) {
						this.setState({
							noDataForDevices: false
						});
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
				}

			}
			catch (err) {
				console.log(err);
			}

		} else {
			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"total-procedures-date-range?MarketType="+this.state.marketType+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;
			console.log("totalprocedures date range url: " + url);

			var token = "";

			if (this.state.currentUser) {
				token = this.state.currentUser.jwtToken;
			}

			if (this.props.authToken) {
				console.log("total-procedures this.props.authToken: " + this.props.authToken);
				token = this.props.authToken;
			}

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: token
					},
				});

				const body = await response.json();
				console.log("totalprocedures date range body response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					if (body.results.length>0) {
						this.setState({ totalProcedures: body.results[0].total }, () => console.log(this.state.totalProcedures));
						this.setState({
							noDataForDevices: false
						});
					} else {
						//this.state.noDataForDevices = true;
						if (this.state.totalProcedures) {
							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					}
					this.forceUpdate();
				} else {
					//this.state.noDataForDevices = true;
					if (this.state.totalProcedures) {
						this.setState({
							noDataForDevices: false
						});
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
				}

			}
			catch (err) {
				console.log(err);
			}

		}

	}

	render() {

		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 250 }}>
					<Row>
						<Col xs="5" md="5" lg="5" className="text-left">
							<CardTitle>Total Procedures
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="TotalProcedurestooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TotalProcedurestooltip" toggle={this.toggleTooltip}>
								Displays total procedures performed by time period. This includes both manual and preset procedures.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="2" md="2" lg="2" className="text-left">
						{this.state.currentDataSetting != 1 &&
							<h4 className="text-center" style={{color: '#fec007', lineHeight: "1.5"}}>{this.state.totalProceduresLabel}</h4>
						}
						</Col>
						<Col xs="5" md="5" lg="5" className="text-right">
							<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'Today')}>Today</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'Week')}>Week</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(3, 'Month')}>Month</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(4, 'Custom Range')}>Custom Range</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						</Col>
					</Row>
					<Fragment>
					{
					// <Row>
					// 	<Col xs="12" md="12" lg="12">
					// 		<div className="mt-1">
					// 			<ButtonGroup style={{ width: '100%' }}>
					// 				<Button
					// 					style={{width: '150px'}}
					// 					color="dark"
					// 					onClick={() => this.onRadioBtnClick(1)}
					// 					active={this.state.rSelected === 1}
					// 				>
					// 					Today
					// 				</Button>
					// 				<Button
					// 					color="dark"
					// 					style={{width: '150px'}}
					// 					onClick={() => this.onRadioBtnClick(2)}
					// 					active={this.state.rSelected === 2}
					// 				>
					// 					This Week
					// 				</Button>
					// 				<Button
					// 					color="dark"
					// 					style={{width: '150px'}}
					// 					onClick={() => this.onRadioBtnClick(3)}
					// 					active={this.state.rSelected === 3}
					// 				>
					// 					This Month
					// 				</Button>
					// 			</ButtonGroup>
					// 		</div>
					// 	</Col>
					// </Row>
					}
					<div className="pt-3 pb-1">
						{this.state.currentDataSetting != 4 ? (
							<Fragment>
							{this.state.currentDataSetting == 2 || this.state.currentDataSetting == 3 ? (
								<Fragment>
								<Row className="align-items-center">
									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
										<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
										</Button>
									</div>
									{this.state.noDevices || this.state.noDataForDevices ? (
										<div className="text-center mt-5 mb-5" style={{ width: '80%', margin: '0 auto', height: '60%' }}>
										{!this.state.noDataForDevices &&
											<h5>User has no devices</h5>
										}
										{this.state.noDataForDevices &&
											<h5>No data for user's devices</h5>
										}
										</div>
									) : (
										<div className="" style={{ width: '80%', margin: '0 auto', height: '60%' }}>
											<Bar height={130} data={this.state.proceduresByDateData} options={lineOptions2} />
										</div>
									)}
									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
										<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
										</Button>
									</div>
								</Row>
								</Fragment>
							) : (
								<Fragment>
								<Row className="align-items-center">
									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
										<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
										</Button>
									</div>
									{this.state.noDevices || this.state.noDataForDevices ? (
										<div className="text-center mt-5 mb-5" style={{ width: '80%', margin: '0 auto', height: '60%' }}>
										{!this.state.noDataForDevices &&
											<h5>User has no devices</h5>
										}
										{this.state.noDataForDevices &&
											<h5>No data for user's devices</h5>
										}
										</div>
									) : (
										<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
											<Row className="mt-5">
												<Col xs="6" md="6" lg="6" className="text-left">
													<span>{this.state.totalProcedures}</span>
												</Col>
												<Col xs="6" md="6" lg="6" className="text-right">
													<span></span>
												</Col>
											</Row>
											<Progress multi className="mt-2">
												<Progress bar color="warning" value={isAdmin ? (this.state.totalProcedures/500)*100 : this.state.totalProcedures} />
											</Progress>
										</div>
									)}
									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
										<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
										</Button>
									</div>
								</Row>
								</Fragment>
							)}
							</Fragment>
						) : (
							<Fragment>
							<Row className="mt-5">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>{this.state.totalProcedures}</span>
								</Col>
								<Col xs="6" md="6" lg="6" className="text-right">
									<span></span>
								</Col>
							</Row>
							<Progress multi className="mt-2">
								<Progress bar color="warning" value={this.state.totalProcedures} />
							</Progress>
							</Fragment>
						)}
					</div>

					{this.state.currentDataSetting == 2 || this.state.currentDataSetting == 3 ? (
						<Fragment>
						<Row className="mt-1">
							<Col lg="12" xl="6" className="text-right">
								{this.state.currentDataSetting == 1 ? (
									<div className="float-left" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>Day:</h6>
									</div>
								) : (
									<div className="float-left" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
									</div>
								)}
								<div className="float-left ml-1" style={{ minWidth: '75%' }}>
									<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
								</div>
							</Col>
							<Col lg="12" xl="6" className="">
								{this.state.currentDataSetting != 1 &&
									<Fragment>
									<div className="float-left text-right" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
									</Fragment>
								}
								{
									// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
									// 	<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals" disabled={this.state.currentDataSetting != 4}>
									// 		GO
									// 	</Button>
									// </div>
								}
							</Col>
						</Row>
						</Fragment>
					) : (
						<Fragment>
						<Row className="pt-4 mt-4">
							<Col lg="12" xl="6" className="text-right">
								{this.state.currentDataSetting == 1 ? (
									<div className="float-left" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>Day:</h6>
									</div>
								) : (
									<div className="float-left" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
									</div>
								)}
								<div className="float-left ml-1" style={{ minWidth: '75%' }}>
									<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
								</div>
							</Col>
							<Col lg="12" xl="6" className="">
								{this.state.currentDataSetting != 1 &&
									<Fragment>
									<div className="float-left text-right" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
									</Fragment>
								}
								{
									// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
									// 	<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals" disabled={this.state.currentDataSetting != 4}>
									// 		GO
									// 	</Button>
									// </div>
								}
							</Col>
						</Row>
						</Fragment>
					)}
					</Fragment>

        </CardBody>
      </Card>
		);
	}
}

export default TotalProcedures;
