import React from 'react';
import { connect } from 'react-redux';
import { Fragment } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './layout-components/header/header';
import Sidebar from './layout-components/sidebar/sidebar';
import Footer from './layout-components/footer/footer';
import Customizer from './layout-components/customizer/customizer';
//import ThemeRoutes from '../routes/router';
import VitalsRoutes from '../routes/VitalsRoutes.js';
import Vitals from '../views/vitals/vitals';
import VitalsCustomize from '../views/vitals/vitals-customize';
import VitalsDashboard from '../views/vitals/vitals-dashboard';
import RemoteSupport from '../views/vitals/remotesupport';
import RemoteSupportBlackBox from '../views/vitals/blackbox';
import VitalsLogs from '../views/vitals/vitals-logs';
import Account from '../views/account/account';
import WarrantyMobile from '../views/vitals/warranty-mobile';
import { authenticationService } from '../jwt/_services';

//Moving router
import Dashboard from '../views/dashboard/dashboard';
import Heartbeat from '../views/heartbeat/heartbeat';
//import Vitals from '../views/vitals/vitals';
//import VitalsCustomize from '../views/vitals/vitals-customize';
//import RemoteSupport from '../views/vitals/remotesupport';
import Users from '../views/users/users';
import Devices from '../views/devices/devices';
import Chat from '../views/chat/chat';
//import Account from '../views/account/account';
import Procedures from '../views/procedures/procedures';
import PBMCalculator from '../views/pbm-calculator/pbm-calculator';
import Calculator2 from '../views/pbm-calculator/calculator-2';
import Calculator3 from '../views/pbm-calculator/calculator-3';
import PBMProtocols from '../views/pbm-calculator/pbm-protocols';
import SoundManagement from '../views/sound-management/sound-management';
import FirmwareManagement from '../views/firmware-management/firmware-management';
import AbortedFirmwareManagement from '../views/abort-management/abort-management';
import ApprovedUsers from '../views/approved-users/approved-users';
import Calibration from '../views/support/calibration';
import Downloads from '../views/support/downloads';
import Feedback from '../views/support/feedback';
import NPSFeedback from '../views/support/nps-feedback';
import FootpedalReport from '../views/support/footpedal-report';
import TopPerformers from '../views/support/top-performers';
import Promotions from '../views/support/promotions';
import Survey from '../views/support/survey';
import WarrantyRecords from '../views/warranty-records/warranty-records';
import DashboardFeedbackView from '../views/feedback/feedback';
import BatchShipmentDateUploadView from '../views/batch-shipment-date/batch-shipment-date-view';
import StatisticsReportView from '../views/statistics-report/statistics-report-view';
import UserMessageLogs from '../views/user-message-logs/user-message-logs';
import ErrorMessages from '../views/error-messages/error-messages';

import TrainingMaterials from '../views/training/training-materials';
import TechSupportVideos from '../views/training/tech-support-videos';
import UltradentSeminars from '../views/training/ultradent-seminars';
import LaserCETraining from '../views/training/laser-ce-training';

//Testing Features
import TestFeature from '../views/test-feature/test-feature';

const mapStateToProps = state => ({
	...state
});

var testCount = 0;

class Fulllayout extends React.Component {

	intervalID = 0;

	constructor(props) {
		super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser fulllayout: "+JSON.stringify(currentUser));

		//console.log("currentUser role: "+JSON.stringify(currentUser.payload["custom:role"]));

		this.updateDimensions = this.updateDimensions.bind(this);
		this.state = {
			isOpen: false,
			currentUser,
			addRoutes: 1,
			width: window.innerWidth,
			ThemeRoutes: [],
			approvedTechList: ["team@geminievo.com"],
			approvedTechList2: ["team@geminievo.com", "ryan.a.stickel@gmail.com", "adisessa@mac.com", "jabe@azenamedical.com", "poppy.insixiengmy@ultradent.com", "orlaina.augustine@ultradent.com", "heather.simpson@ultradent.com", "shyann.holliday@ultradent.com"],
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook, Applies when loading or resizing App                           */
	/*--------------------------------------------------------------------------------*/
	componentDidMount() {
		window.addEventListener("load", this.updateDimensions);
		window.addEventListener("resize", this.updateDimensions);

		this.generateThemeRoutes();

		if (!this.state.currentUser.payload['custom:role']) {
			//this.intervalID = setInterval(this.generateThemeRoutes.bind(this), 5000);
		}

	}


	generateThemeRoutes() {
		var deviceList = JSON.parse(localStorage.getItem('deviceList'));
		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser router: "+JSON.stringify(currentUser));

		const isApprovedTech = this.state.approvedTechList.find(techEmail => currentUser.payload.email === techEmail);

		var upgradeLevel = true;

		//UNCOMMENT TO TURN ON USER UPGRADE FUNCTIONALITY
		//var upgradeLevel = JSON.parse(localStorage.getItem('upgradeLevel'));
		console.log("fulllayout deviceList: "+JSON.stringify(deviceList));

		var deviceSubMenuList = [];

		var intlDevices = [];

		if (this.state.deviceList) {
			intlDevices = this.state.deviceList.filter(device => device.Kit.toLowerCase() === "intl");
		}

		//console.log("intlDevices: " + JSON.stringify(intlDevices));
		//console.log("intlDevices.length: " + JSON.stringify(intlDevices.length));

		testCount++;

		if (deviceList) {
			for(var i=0; i<deviceList.length; i++) {

				var subchild = [];
				if (currentUser) {
					if (currentUser.payload["custom:role"]) {
						if (currentUser.payload["custom:role"]>1) {
							subchild = [
								{
									path: "/vitals/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Vitals',
									icon: 'mdi mdi-stethoscope',
									component: Vitals
								},
								{
									path: "/customize/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Customize',
									icon: 'mdi mdi-message-settings-variant',
									component: VitalsCustomize
								},
								{
									path: "/remotesupport/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Remote Support',
									icon: 'mdi mdi-lan-connect',
									component: RemoteSupport
								},
								{
									path: "/device-logs/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Device Logs',
									icon: 'mdi mdi-lan-connect',
									component: VitalsLogs
								}
							]
						} else if (currentUser.payload["custom:role"]==0) {
							subchild = [
								{
									path: "/vitals/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Vitals',
									icon: 'mdi mdi-stethoscope',
									component: Vitals
								},
								{
									path: "/customize/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Customize',
									icon: 'mdi mdi-message-settings-variant',
									component: VitalsCustomize
								}
							]
						} else {
							subchild = [
								{
									path: "/vitals/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Vitals',
									icon: 'mdi mdi-stethoscope',
									component: Vitals
								},
								{
									path: "/customize/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Customize',
									icon: 'mdi mdi-message-settings-variant',
									component: VitalsCustomize
								},
								{
									path: "/remotesupport/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Remote Support',
									icon: 'mdi mdi-lan-connect',
									component: RemoteSupport
								}
							]
						}
					} else {
						subchild = [
							{
								path: "/vitals/"+deviceList[i].SerialNumber,
								serial: deviceList[i].SerialNumber,
								name: 'Vitals',
								icon: 'mdi mdi-stethoscope',
								component: Vitals
							},
							{
								path: "/customize/"+deviceList[i].SerialNumber,
								serial: deviceList[i].SerialNumber,
								name: 'Customize',
								icon: 'mdi mdi-message-settings-variant',
								component: VitalsCustomize
							}
						]
					}
				}

				var device = {
					collapse: true,
					name: deviceList[i].Nickname+" "+testCount,
					state: deviceList[i].Nickname,
					subchild: subchild
				};


				console.log("New device menu item: "+JSON.stringify(device));
				deviceSubMenuList.push(device);
				//deviceSubMenuList.push(device2);
			}
			console.log("MY deviceList menu: "+JSON.stringify(deviceSubMenuList));
		}

		var ThemeRoutes = [
			{
				collapse: false,
				open: false,
				name: "Dashboard",
				path: "/dashboard",
				state: "dashboard",
				icon: "mdi mdi-view-dashboard",
				component: Dashboard,
				child: [

				]
			},
			{
				navlabel: true,
				name: "No Devices",
				icon: "mdi mdi-file-document-box",
			},
			{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

		];

		if (deviceList) {
			if (deviceList.length>0 && upgradeLevel) {

				//console.log("fulllayout has devicelist");

				if (intlDevices.length>0) {

					//console.log("Has INTL devices");

					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Your Devices",
							state: "devices",
							icon: "mdi mdi-file-document-box",
							child: deviceSubMenuList
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				} else {

					//console.log("Does NOT have INTL devices");

					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Your Devices",
							state: "devices",
							icon: "mdi mdi-file-document-box",
							child: deviceSubMenuList
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
								{
									path: "/chat",
									name: 'Tech Support Chat',
									state: "chat support",
									icon: 'mdi mdi-comment-text',
									component: Chat
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				}

			} else {

				if (intlDevices.length>0) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							navlabel: true,
							name: "No Devices",
							icon: "mdi mdi-file-document-box",
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
								{
									path: "/chat",
									name: 'Tech Support Chat',
									state: "chat support",
									icon: 'mdi mdi-comment-text',
									component: Chat
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				} else {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							navlabel: true,
							name: "No Devices",
							icon: "mdi mdi-file-document-box",
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
								{
									path: "/chat",
									name: 'Tech Support Chat',
									state: "chat support",
									icon: 'mdi mdi-comment-text',
									component: Chat
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				}

			}
		} else {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					navlabel: true,
					name: "No Devices",
					icon: "mdi mdi-file-document-box",
				},
				{
					collapse: true,
					open: true,
					name: "Training",
					state: "training",
					icon: "mdi mdi-message-video",
					child:  [
						{
							path: "/clinical-videos",
							name: 'Clinical Videos',
							icon: 'mdi mdi-message-video',
							component: TrainingMaterials
						},
						{
							path: "/laser-ce-training",
							name: 'Free Laser Training',
							icon: 'mdi mdi-message-video',
							component: LaserCETraining
						},
						{
							path: "/ultradent-seminars",
							name: 'Ultradent Seminars',
							icon: 'mdi mdi-message-video',
							component: UltradentSeminars
						},
						{
							path: "/tech-support-videos",
							name: 'Technical Support Videos',
							icon: 'mdi mdi-message-video',
							component: TechSupportVideos
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Photobiomodulation (PBM)",
					state: "calculators",
					icon: "",
					child:  [
						{
							path: "/pbm-protocols",
							name: 'PBM Protocols',
							icon: 'mdi mdi-cloud-download',
							component: PBMProtocols
						},
						{
							path: "/pbm-calculator",
							name: 'PBM Dosage Calculator',
							icon: 'mdi mdi-calculator',
							component: PBMCalculator
						}
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-rocket",
					child:  [
						{
							path: "/downloads",
							name: 'Downloads',
							state: "downloads",
							icon: 'mdi mdi-cloud-download',
							component: Downloads
						},
						{
							path: "/calibration",
							name: 'Calibration',
							state: "calibration",
							icon: 'mdi mdi-chart-bar',
							component: Calibration
						},
						{
							path: "/promotions",
							name: 'Promotions',
							state: "promotions",
							icon: 'mdi mdi-gift',
							component: Promotions
						},
						{
							path: "/survey",
							name: 'Survey',
							state: "survey",
							icon: 'mdi mdi-server',
							component: Survey
						},
						{
							path: "/feedback",
							name: 'Feedback',
							state: "feedback",
							icon: 'mdi mdi-contact-mail',
							component: Feedback
						},
						{
							path: "/chat",
							name: 'Tech Support Chat',
							state: "chat support",
							icon: 'mdi mdi-comment-text',
							component: Chat
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser) {
			if (currentUser.payload["custom:role"] && currentUser.payload["custom:role"]==1) {

				if (isApprovedTech) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Tech Support Chat",
							path: "/chat",
							state: "chat",
							icon: "mdi mdi-comment-text",
							component: Chat,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/users', name: 'Users', redirect: true }

					];
				} else {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/users', name: 'Users', redirect: true }

					];
				}

				if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="us-azena" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Footpedal Report",
							path: "/footpedal-report",
							state: "footpedal-report",
							icon: "mdi mdi-library-books",
							component: FootpedalReport,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									collapse: false,
									open: false,
									path: "/warranty-records",
									name: "Warranty Records",
									state: "warranty-records",
									icon: "mdi mdi-shield-outline",
									component: WarrantyRecords,
									child: [

									]
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/users', name: 'Users', redirect: true }

					];
				}

			} else if (currentUser.payload["custom:role"] && currentUser.payload["custom:role"]>1) {

				if (isApprovedTech) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Chat Support",
							path: "/chat",
							state: "chat",
							icon: "mdi mdi-comment-text",
							component: Chat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Top Performers",
							path: "/top-performers",
							state: "top-performers",
							icon: "mdi mdi-trophy-outline",
							component: TopPerformers,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/error-messages",
							name: "Error Messages",
							state: "error-messages",
							icon: "mdi mdi-google-circles-extended",
							component: ErrorMessages,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/warranty-records",
							name: "Warranty Records",
							state: "warranty-records",
							icon: "mdi mdi-shield-outline",
							component: WarrantyRecords,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/calibration",
							name: "Calibration",
							state: "calibration",
							icon: "mdi mdi-chart-bar",
							component: Calibration,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "User Feedback",
							path: "/user-feedback",
							state: "user-feedback",
							icon: "mdi mdi-library-books",
							component: DashboardFeedbackView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "NPS User Feedback",
							path: "/nps-user-feedback",
							state: "nps-user-feedback",
							icon: "mdi mdi-library-books",
							component: NPSFeedback,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Footpedal Report",
							path: "/footpedal-report",
							state: "footpedal-report",
							icon: "mdi mdi-library-books",
							component: FootpedalReport,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Purchase Date Upload",
							path: "/batch-shipment-date-upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Statistics Report",
							path: "/statistics-report",
							state: "statistics-report",
							icon: "mdi mdi-library-books",
							component: StatisticsReportView,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Software Update",
							state: "software-update",
							icon: "mdi mdi-briefcase-upload",
							child:  [
								{
									name: "Firmware Management",
									path: "/firmware-management",
									icon: "mdi mdi-briefcase-upload",
									component: FirmwareManagement,
								},
								{
									name: "Sound Management",
									path: "/sound-management",
									icon: "mdi mdi-briefcase-upload",
									component: SoundManagement,
								},
								{
									name: "Approved Users",
									path: "/approved-users",
									icon: "mdi mdi-briefcase-upload",
									component: ApprovedUsers,
								},
								{
									name: "Aborted Firmware",
									path: "/aborted-firmware",
									icon: "mdi mdi-briefcase-upload",
									component: AbortedFirmwareManagement,
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				} else {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Top Performers",
							path: "/top-performers",
							state: "top-performers",
							icon: "mdi mdi-trophy-outline",
							component: TopPerformers,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								}
							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/error-messages",
							name: "Error Messages",
							state: "error-messages",
							icon: "mdi mdi-google-circles-extended",
							component: ErrorMessages,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/warranty-records",
							name: "Warranty Records",
							state: "warranty-records",
							icon: "mdi mdi-shield-outline",
							component: WarrantyRecords,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/calibration",
							name: "Calibration",
							state: "calibration",
							icon: "mdi mdi-chart-bar",
							component: Calibration,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "User Feedback",
							path: "/user-feedback",
							state: "user-feedback",
							icon: "mdi mdi-library-books",
							component: DashboardFeedbackView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "NPS User Feedback",
							path: "/nps-user-feedback",
							state: "nps-user-feedback",
							icon: "mdi mdi-library-books",
							component: NPSFeedback,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Footpedal Report",
							path: "/footpedal-report",
							state: "footpedal-report",
							icon: "mdi mdi-library-books",
							component: FootpedalReport,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Purchase Date Upload",
							path: "/batch-shipment-date-upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Statistics Report",
							path: "/statistics-report",
							state: "statistics-report",
							icon: "mdi mdi-library-books",
							component: StatisticsReportView,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Software Update",
							state: "software-update",
							icon: "mdi mdi-briefcase-upload",
							child:  [
								{
									name: "Firmware Management",
									path: "/firmware-management",
									icon: "mdi mdi-briefcase-upload",
									component: FirmwareManagement,
								},
								{
									name: "Sound Management",
									path: "/sound-management",
									icon: "mdi mdi-briefcase-upload",
									component: SoundManagement,
								},
								{
									name: "Approved Users",
									path: "/approved-users",
									icon: "mdi mdi-briefcase-upload",
									component: ApprovedUsers,
								},
								{
									name: "Aborted Firmware",
									path: "/aborted-firmware",
									icon: "mdi mdi-briefcase-upload",
									component: AbortedFirmwareManagement,
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				}

			}
		}

		this.setState({ ThemeRoutes });
	}


	/*--------------------------------------------------------------------------------*/
	/*Function that handles sidebar, changes when resizing App                        */
	/*--------------------------------------------------------------------------------*/
	updateDimensions() {
		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 1170) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
					element.classList.remove("mini-sidebar");
				}
				break;

			case 'overlay':
				if (this.state.width < 767) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
				}
				break;

			default:
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook                                                                 */
	/*--------------------------------------------------------------------------------*/
	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
	}
	render() {
		//console.log("currentUser role fulllayout: "+JSON.stringify(this.state.currentUser.role));
		/*--------------------------------------------------------------------------------*/
		/* Theme Setting && Layout Options wiil be Change From Here                       */
		/*--------------------------------------------------------------------------------*/
		return (
			<div
				id="main-wrapper"
				dir={this.props.settings.activeDir}
				data-theme={this.props.settings.activeTheme}
				data-layout={this.props.settings.activeThemeLayout}
				data-sidebartype={this.props.settings.activeSidebarType}
				data-sidebar-position={this.props.settings.activeSidebarPos}
				data-header-position={this.props.settings.activeHeaderPos}
				data-boxed-layout={this.props.settings.activeLayout}
			>
				{/*--------------------------------------------------------------------------------*/}
				{/* Header                                                                         */}
				{/*--------------------------------------------------------------------------------*/}
				<Header />
				{/*--------------------------------------------------------------------------------*/}
				{/* Sidebar                                                                        */}
				{/*--------------------------------------------------------------------------------*/}
				<Sidebar {...this.props} routes={this.state.ThemeRoutes} />
				{/*--------------------------------------------------------------------------------*/}
				{/* Page Main-Content                                                              */}
				{/*--------------------------------------------------------------------------------*/}
				<div className="page-wrapper d-block">
					<div className="page-content container-fluid">
						<Switch>
							{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Route path="/vitals/:id" component={Vitals} key="1000" />
							}
							{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Route path="/customize/:id" component={VitalsCustomize} key="1001" />
							}
							{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Route path="/remotesupport/:id" component={RemoteSupport} key="1002" />
							}
							{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Route path="/remotesupportblackbox/:id" component={RemoteSupportBlackBox} key="1005" />
							}
							{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Route path="/vitals-dashboard/:id" component={VitalsDashboard} key="1003" />
							}
							{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>1 &&
								<Route path="/device-logs/:id" component={VitalsLogs} key="1004" />
							}

							<Route path="/account" component={Account} key="1006" />
							{this.state.ThemeRoutes.map((prop, key) => {
								if (prop.navlabel) {
									return null;
								}
								else if (prop.collapse) {
									return prop.child.map((prop2, key2) => {
										if (prop2.collapse) {
											return prop2.subchild.map((prop3, key3) => {

												if (prop3.path.includes("vitals")) {
													console.log("is vitals");
													return (
														<Route path="/vitals/:id" key={key3} component={props => (<Vitals {...props} serial={prop3.serial} />)} />
													);
												} else if (prop3.path.includes("customize")) {
													console.log("is customize");
													return (
														<Route path="/customize/:id" key={key3} component={props => (<VitalsCustomize {...props} serial={prop3.serial} />)} />
													);
												} else {
													return (
														<Route path={prop3.path} component={prop3.component} key={key3} />
													);
												}
											});
										}
										return (
											<Route path={prop2.path} component={prop2.component} key={key2} />
										);
									});
								}
								else if (prop.redirect) {
									return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
								}
								else {
									return (
										<Route path={prop.path} component={prop.component} key={key} />
									);
								}
							})}
						</Switch>
					</div>
					<Footer />
				</div>
				{/*--------------------------------------------------------------------------------*/}
				{/* Customizer from which you can set all the Layout Settings                      */}
				{/*--------------------------------------------------------------------------------*/}

			</div>
		);
	}
}
export default connect(mapStateToProps)(Fulllayout);
