
import React, { Component, Fragment, useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
  Button
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";
import {
	UsedPowerSettings,
	TotalProcedures,
	LaserUsage,
	TopProcedures,
	ReturnOnInvestment,
  TotalHourlyUsagePVM,
  TopProceduresCat,
  WavelengthUsage
} from '../../components/vitals-dashboard/';

import update from 'react-addons-update';
import redpin from '../dashboard/imgs/red_pin.png';
import greenpin from '../dashboard/imgs/green_pin.png';

var messagesArray = [];
var online_markers = [];
var devicesIcons = [];

class VitalsDashboard extends React.Component {

	intervalID = 0;
	intervalID2 = 1;

	//messagesArray = [];

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

    this.state = {
			mainMessage: [''],
			subMessage: [''],
			online_markers: [],
			devicesLoaded: false,
			currentUser,
			client: null,
      messageToSend:'',
      isConnected: false,
			devicesLogged: [],
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			devices: [],
			onlineDevices: [],
			devicesIcons: [],
			count: 0,
			handlingMessage: false,
      currentDevice: this.props.match.params.id,
	  };

		this.handleMessage = this.handleMessage.bind(this);
		this.testCall = this.testCall.bind(this);

  }

	componentDidMount() {

		ReactGA.pageview(window.location.pathname);

		this.connectToMQTT();
		this.getData();

		this.intervalID2 = setInterval(this.handleMessage, 3000);
		this.intervalID = setInterval(this.getMessage, 5000);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
		clearInterval(this.intervalID2);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ devices: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

		for ( var i = 0; i < this.state.devices.length; i++ ) {
      devicesIcons.push( {
				serial_number: this.state.devices[i].SerialNumber,
        current_icon: redpin
			});
		}

    this.setState({ devicesLoaded: true });
	};

	connectToMQTT = async () => {
		authenticationService.getGlobalConnectionURL()
				.then(
						connectionUrl => {
							console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								console.log('random hash: ' + hash);
								this.state.client = new Paho.Client(connectionUrl.response, hash+this.props.match.params.id);
								this.state.client.onMessageArrived = this.onMessageArrived;
								this.state.client.onConnectionLost = this.onConnectionLost;
								this.state.client.connect({
									timeout: 3,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

	onMessageArrived(entry) {
    //console.log("dashboard onMessageArrived:"+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);
		if (msg.batch) {
			//this.setState({mainMessage: msg});

			// if (messagesArray.length==0) {
			// 	messagesArray.push(msg);
			// }

			messagesArray.push(msg);
			//console.log("dashboard messagesArray:"+messagesArray);
			//console.log("dashboard_main_messages:"+this.state.mainMessage);
			//this.getLocationData(msg);
		} else {
			//this.setState({subMessage: JSON.parse(entry.payloadString)});
			//console.log("dashboard_sub_messages:"+this.state.subMessage);
		}

		//this.handleMessage();

  }

	testCall() {

		let messages = messagesArray;
		console.log("dashboard messagesArray 2:"+messages);
	}


	handleMessage = async () => {

		let messages = messagesArray;
		//console.log("dashboard messagesArray 2:"+messages);

		//this.setState({ handlingMessage: true });

		console.log("Handling Message");

		// for ( var i = 0; i < messages.length; i++ ) {
		// 	console.log("dashboard messages length: "+messages.length);
		// }

		var resetArray = false;

		for ( var i = 0; i < messages.length; i++ ) {

			let msg = messages[i];

			var found_online_marker = false;

			var anIndex = 0;
			var onlineDevice = online_markers.find(function(device, index) {

				//console.log('handleMessage device.serial_number: ' + JSON.stringify(device.serial_number));
				//console.log('handleMessage msg.serial_num: ' + JSON.stringify(msg.serial_num));

				if(device.serial_number == msg.serial_num) {
					online_markers[ index ].last_update = new Date();
					//console.log('handleMessage found serial: ' + JSON.stringify(msg.serial_num));
					found_online_marker = true;
					return true;
				}
			});


			// for ( var i = 0; i < this.state.online_markers.length; i++ ) {
			// 	if ( this.state.online_markers[ i ].serial_number == msg.serial_num ) {
			// 		this.state.online_markers[ i ].last_update = new Date();
			// 		found_online_marker = true;
			// 	}
			// }

			if ( found_online_marker == false ) {
				//console.log("unit on msg: "+JSON.stringify(msg));

				var city = "Unknown";
				var state = "Unknown";
				var country = "Unknown";

				let device = this.state.devices.find(d => d.SerialNumber === msg.serial_num);

				if (device) {

					console.log("handleMessage device not found serial: "+device.SerialNumber);
					console.log("handleMessage device not found city: "+device.geo_city);
					
					city = device.geo_city;
					state = device.geo_state;
					country = device.geo_country;
				}

				// for ( var i = 0; i < this.state.devices.length; i++ ) {
				// 	if ( this.state.devices[ i ].SerialNumber == msg.serial_num ) {
				// 		city = this.state.devices[ i ].geo_city;
				// 		state = this.state.devices[ i ].geo_state;
				// 		country = this.state.devices[ i ].geo_country;
				// 	}
				// }

				let life810 = msg.life810;
				let life980 = msg.life980;

				var d = Number(life810 + life980);
				var h = Math.floor(d / 3600);
				var m = Math.floor(d % 3600 / 60);
				var s = Math.floor(d % 3600 % 60);

				var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
				var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
				var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

				let usageTotal = hDisplay;

				if (h == 0) {
					usageTotal = mDisplay;
				}

				if (m == 0) {
					usageTotal = sDisplay;
				}

				online_markers.push( {
					serial_number: msg.serial_num,
	        icon: greenpin,
					last_update: new Date(),
					city: city,
					state: state,
					country: country,
					version: msg.fw_ver,
					usage: usageTotal
				});

				console.log('handleMessage online markers count: ' + JSON.stringify(online_markers.length));
				//console.log('handleMessage online markers: ' + JSON.stringify(online_markers));
			}

	    // for ( var i = 0; i < online_markers.length; i++ ) {
			//
			// 	var myIndex = 0;
			// 	var serialNum = online_markers[ i ].serial_number;
			//
			// 	var deviceIcons = devicesIcons.find(function(device, index) {
			// 		if(device.serial_number == serialNum) {
			// 			myIndex = index
			// 			return true;
			// 		}
			// 	});
			//
			// 	devicesIcons[myIndex].current_icon = greenpin;
			//
			// 	// this.setState(update(this.state, {
			// 	// 	devicesIcons: {
			// 	// 		[myIndex]: {
			// 	// 			current_icon: {
			// 	// 				$set: greenpin
			// 	// 			}
			// 	// 		}
			// 	// 	}
			// 	// }));
			//
	    //   // for ( var j = 0; j < this.state.devicesIcons.length; j++ ) {
	    //   //   if ( this.state.online_markers[ i ].serial_number == this.state.devicesIcons[j].serial_number ) {
	    //   //     this.setState(update(this.state, {
	    //   //     	devicesIcons: {
	    //   //     		[j]: {
	    //   //           current_icon: {
	    //   //             $set: greenpin
	    //   //           }
	    //   //     		}
	    //   //     	}
	    //   //     }));
	    //   //     //this.state.devicesIcons[j].current_icon = 'https://img.icons8.com/emoji/48/000000/green-heart.png';
	    //   //     //console.log("Serial:"+this.state.devicesIcons[j].serial_number+" current_icon:"+this.state.devicesIcons[j].current_icon);
	  	// 	// 	}
	    //   // }
			// }

			if (i == (messages.length-1)) {
				resetArray = true;
			}

		}

		if (resetArray) {
			messagesArray = [];
		}


		//this.setState({ handlingMessage: false });

	}

	getMessage = () => {
    //console.log('mapcontainer lat: ' + this.mainMessage.geo_lat);
    //console.log('mapcontainer lon: ' + this.mainMessage.geo_lng);
    console.log('online markers count: ' + JSON.stringify(online_markers.length));
    //console.log('all markers: ' + JSON.stringify(this.state.markerObjects[12].icon.url));
    //this.state.devices[12].SerialNumber = "00100";
    //console.log('updated serial: ' + this.state.devices[12].SerialNumber);
		//console.log('devicesIcons: ' + this.state.devicesIcons);

		for ( var a = 0; a < online_markers.length; a++ ) {

			if ( new Date() - online_markers[ a ].last_update > 10000 ) {
				online_markers[a].icon = redpin;
			} else {
				online_markers[a].icon = greenpin;
			}

		}

  }

	onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})
		//this.sendMessage("@@@get_all");
		console.log('myCurrentDevice id: ' + this.state.currentDevice);
		//this.setState({currentDevice: this.state.currentDevice});
		//const subChannel = "0#";
		const subChannel = "#";
		this.state.client.subscribe(subChannel);
  };

  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
			this.connectToMQTT();
    }
  }

	render() {
    var vitalsPath = "/vitals/"+this.state.currentDevice;
    var customizePath = "/customize/"+this.state.currentDevice;
    var remoteSupportPath = "/remotesupport/"+this.state.currentDevice;
		var deviceLogs = "/device-logs/"+this.state.currentDevice;
		return (
			<div>
        <Row className="mb-3">
          <Col lg="6" xl="3">
            <h1 style={{color:'#e9c636'}}>Dashboard</h1>
          </Col>
          <Col lg="6" xl="3">
            <h4 className="pt-3">Serial {this.state.currentDevice}</h4>
          </Col>
          <Col lg="6" xl="6">
						<div className="float-right">
            <Button color="dark" href={vitalsPath}>
              Vitals
            </Button>
						<Button className="ml-2" color="dark" href={customizePath}>
							Customize
						</Button>
						{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
							<Fragment>
								<Button className="ml-2" color="dark" href={remoteSupportPath}>
									Remote Support
								</Button>
								<Button className="ml-2" color="dark" href={deviceLogs}>
									Device Logs
								</Button>
							</Fragment>
						}
						</div>
          </Col>
        </Row>
				<Row>
					<Col lg="6" xl="4">
						<TotalHourlyUsagePVM currentDevice={this.state.currentDevice} />
						<UsedPowerSettings currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<TotalProcedures currentDevice={this.state.currentDevice} />
						<LaserUsage currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<WavelengthUsage currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<TopProcedures currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<TopProceduresCat currentDevice={this.state.currentDevice} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default VitalsDashboard;
